import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import accordionSelected from '../../../../assets/accordion-selected.svg'
import accordionCollapse from '../../../../assets/accordion-collapse.svg'
import closeBtn from '../../../../assets/close-btn.svg'
import Accordion from '../../../Common/Accordion';

const FeatureDevmicrositeFloorPlans = (props) => {

  const {
    Header = null,
    HeaderFontName = null,
    HeaderFontSize = {value: 'default'},
    BackgroundColour = null,
    ImageBackgroundColour = null,
    FontColour = null,
    FloorPlanID = null,
    FloorPlans = [],
    ImagePosition = null,
  } = props.fields

  const {
    RemoveDefaultMargin = ""
  } = props.params || {};

  const [selectedFloorId, setSelectedFloorId] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [accordionHeight, setAccordionHeight] = useState(null)
  const [hasArrows, setHasArrows] = useState()

  const componentStyle = {
    marginTop: RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "0px" : ""
  }

  const styling = {
    color: FontColour && FontColour.value ? FontColour.value : "#ffffff",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#3d4a39",
  }

  // pinch-zoom parameters
  const flrPlanImgRef = useRef()
  const imgRef = useRef()
  const quickPinchZoomRef = useRef()
  const onUpdate = useCallback(({ x, y, scale }) => {
    // console.log('params', x, y, scale)
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);

    }
  }, []);

  useEffect(() => {
    if (!isModalOpen && quickPinchZoomRef && quickPinchZoomRef.current) quickPinchZoomRef.current.scaleTo({ x: 0, y: 0, scale: 1 })
  }, [isModalOpen])

  //  animate immage trigger
  const updateSelectedImage = (id) => {
    const { current: img } = flrPlanImgRef;
    //const img = document.getElementById("flr-plan-img")
    if (img) {
      img.classList.remove('img-animate')
      img.setAttribute('src', FloorPlans[id].fields.ImageUrl.value)
      img.setAttribute('alt', FloorPlans[id].fields.ImageAltText.value)
      void img.offsetWidth //trigger reflow
      img.classList.add('img-animate');
    }

  }
  useEffect(() => {
    updateSelectedImage(selectedFloorId)
  }, [selectedFloorId])

  useEffect(() => {
    const accordionArrows = document.querySelector('.accordion.arrows-active')
    setHasArrows(accordionArrows)
  }, [hasArrows])

  // full image modal handler
  const modalHandler = () => {
    const body = document.body
    if (body.classList.contains('modal-open')) body.classList.remove('modal-open')
    else body.classList.add('modal-open')
    setIsModalOpen(!isModalOpen)
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setAccordionHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    FloorPlans && FloorPlans.length > 0 &&
    <div className="floor-plans" style={componentStyle}>
      <div className='row no-gutters'>
        {/* floor plan */}
        <div
          className={`col-12 col-30`}
          style={ImagePosition.value === "left" ? { order: 13 } : {}}
        >
          <div className={`floor-plans-floors-container ${hasArrows ? "hasArrows" : ""}`} style={styling} ref={measuredRef}>
              <div className={`header ${HeaderFontSize.value === 'default' ? "" : HeaderFontSize.value}`} style={{ fontFamily: HeaderFontName && HeaderFontName.value }}>
                <Text field={Header && Header.value ? Header : ""} />
              </div>
            {/* accordion */}
            <Accordion
              id={FloorPlanID.value}
              items={FloorPlans}
              selectedId={selectedFloorId}
              setSelectedId={setSelectedFloorId}
              selectedItemImage={accordionSelected}
              collapseItemImage={accordionCollapse}
              styles={styling}
            />
          </div>

        </div>
        {/* image */}
        <div
          className={`col-12 col-70`}
          style={ImagePosition.value === "left" ? { order: 1 } : {}}
        >
          <div className='floor-plans-image-container' style={{ backgroundColor: ImageBackgroundColour.value ? ImageBackgroundColour.value : '#fff' }}>
            <img ref={flrPlanImgRef} id="flr-plan-img" className="floor-plan img-animate" onClick={modalHandler} style={{ '--imgMaxHeight': accordionHeight + 'px' }}></img>
            <div className={`fullscreen-img-modal ${isModalOpen && 'modal-open'}`}>
              <button onClick={modalHandler}><img className="close-btn" src={closeBtn} /></button>
              <div className="img-zoomarea">
                <QuickPinchZoom
                  ref={quickPinchZoomRef}
                  onUpdate={onUpdate} style={{ height: "100%", width: "100%" }}
                  tapZoomFactor={0}
                  className="pinch-zoom">
                  <img ref={imgRef} src={FloorPlans[selectedFloorId].fields.ImageUrl.value} alt={FloorPlans[selectedFloorId].fields.ImageAltText.value}></img>
                </QuickPinchZoom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
};

export default FeatureDevmicrositeFloorPlans;
