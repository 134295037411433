import React, {useEffect, useState} from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getAncestorElement } from '../../../../helper/GetAncestorElement';
import chevronDown from '../../../../assets/image/chevron-down-circle.svg';
import defaultImg from '../../../../assets/image/grey.svg';

const FeatureDevMicrositeHero = (props) => {
  const {
    HeroUrl,
    HeroAlt,
    HeroType,
    HeroTitle,
    TitleColour,
    TitleFontName,
    HeroTitleFontSize = {value: 'default'},
    HeroTagline,
    TaglineFontName,
    HeroTaglineFontSize = {value: 'default'}
  } = props.fields;

  const titleStyle = {
    color: TitleColour && TitleColour.value ? TitleColour.value : "#ffffff",
    fontFamily: TitleFontName && TitleFontName.value
  }

  const taglineStyle = {
    color: TitleColour && TitleColour.value ? TitleColour.value : "#ffffff",
    fontFamily: TaglineFontName && TaglineFontName.value
  }

  const [chevronStyle, setChevronStyle] = useState()

  useEffect(() => {
    const getHeader = document.querySelector(".main-navigation__nav-menu");
    if (getHeader) {
      const headerStyle = window.getComputedStyle(getHeader);
      const headerColor = headerStyle.getPropertyValue('color');
      
      if (headerColor) {
        setChevronStyle(headerColor);
      } else {
        return "#ffffff";
      }
    }
  }, [chevronStyle]);

  const renderHeroBanner = () => {
    let heroBannerLayout = "";

    if (HeroUrl && HeroUrl.value) {
      if (HeroType && HeroType.value && HeroType.value.toLowerCase() === "video") {
        heroBannerLayout =
          <video
            controls={false}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          >
            <source src={HeroUrl.value} type="video/mp4" />
            <source src={HeroUrl.value} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
      } else {
        heroBannerLayout =
        <img
        alt={
          HeroAlt && HeroAlt.value
            ? HeroAlt.value
            : "dev-hero-alt"
        }
        src={defaultImg}
        data-src={HeroUrl.value}
        onError={(e) => e.target.src = defaultImg}
        className="lazyload"
      />
      }
    }
    return heroBannerLayout;
  }

  const heroBtnHandler = (e) => {
    e.preventDefault()
    let element = e.target;
    const parentWrapper = getAncestorElement(element, 'dev-hero');
    if (parentWrapper) {
      const nextSibling = parentWrapper.nextElementSibling;

      if (nextSibling) {
        let initialTop = window.pageYOffset;
        let inputTop = nextSibling.offsetTop;
        let defaultTop = (initialTop + inputTop) * 0.5;
        let difference = initialTop - defaultTop;
        let startTime = performance.now();

        function step() {
          let animationTime = (performance.now() - startTime) / 1000;
          if (animationTime > 1) animationTime = 1;
          window.scrollTo(0, defaultTop + difference * Math.cos(animationTime * Math.PI));
          if (animationTime < 1) window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
      }
    }
  }

  if (renderHeroBanner() || HeroTitle && HeroTitle.value || HeroTagline && HeroTagline.value) {
    return (
      <div className='dev-hero'>
        <div className='dev-hero__image'>
          {renderHeroBanner()}
        </div>
        <div className='dev-hero__text'>
          <div className='container'>
              {HeroTagline &&
                HeroTagline.value &&
                <div className={`dev-hero__tagline ${HeroTaglineFontSize.value === 'default' ? "" : HeroTaglineFontSize.value} ${HeroTitle && HeroTitle.value ? "" : "noTitle"}`} style={taglineStyle}>
                  <Text field={HeroTagline} />
                </div>
              }
              {HeroTitle &&
                HeroTitle.value &&
                <div className={`dev-hero__title ${HeroTitleFontSize.value === 'default' ? "" : HeroTitleFontSize.value}`} style={titleStyle}>
                  <Text field={HeroTitle} />
                </div>
              }
          </div>

          <button className='dev-hero__button'
            onClick={heroBtnHandler}>
            <div className='btn-arrow-down' style={{backgroundColor: chevronStyle}}>
              <img src={chevronDown} alt="chevron-down" style={{opacity: 0}}/>
            </div>
          </button>
        </div>
      </div>
    )
  } else return null;

};

export default FeatureDevMicrositeHero;