import React, { Component, useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

const Checkbox = (props) => {
    const [checkBoxState, setCheckBoxState] = useState({
        isInvalid: true,
            isTouched: false
    })

    const {
        className = null,
        id = null,
        name = null,
        uniqueKey = null,
        handleCheckboxClick = null,
        checked = false,
        isRequired = false,
        errorMessage = null,
        isSubmitted = false,
    } = props || {};

    const {
        isInvalid,
        isTouched
    } = checkBoxState;

    return (
        <div className="form-group form-checkbox" key={uniqueKey}>
            <label>
                <input
                    type="checkbox"
                    className={`${className} ${checked ? "is-active" : ""}`}
                    id={id}
                    name={id}
                    value={name}
                    onClick={e => {
                        handleCheckboxClick(e)
                        setCheckBoxState({
                            isInvalid: !e.target.checked,
                            isTouched: true
                        })
                    }}
                    autoComplete="off"
                />
                <span className={`${((isTouched || isSubmitted) && isRequired && isInvalid) ? "is-required form-control-error isInvalid" : ""}`}>
                    <RichText field={{ value: name }} />
                </span>
            </label>
            {(isTouched || isSubmitted) &&
                isRequired &&
                isInvalid &&
                <div className="form-checkbox__error">
                    {errorMessage}
                </div>
            }

        </div>
    )
}

export default Checkbox;