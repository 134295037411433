import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import RiseAndFade from '../../../Common/Animations/RiseAndFade';
import defaultImg from "../../../../assets/image/gray.png";

const FeatureDevMicrositeHeroGrid = (props) => {
  const {
    Heading,
    HeadingFontName,
    HeadingFontSize = {value: 'default'},
    Subheading,
    SubheadingFontName,
    SubheadingFontSize = {value: 'default'},
    GridImages,
    BackgroundColour,
    FontColour
  } = props.fields;

  const {
    RemoveDefaultMargin = ""
  } = props.params || {};

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window) {
      if (window.outerWidth < 768) {
        setIsMobile(true)
      }
    }
  }, [])

  let fontColor = FontColour && FontColour.value ? FontColour.value : "#000000";
  const heroGridStyle = {
    color: fontColor,
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#ffffff",
    paddingTop: RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "0px" : ""
  }

  const linkLabelStyle = {
    backgroundImage: `linear-gradient(transparent calc(100% - 1px), ${fontColor} 1px)`
  }

  const renderGallery = () => {
    let galleryLayout = "";

    if (GridImages && GridImages.length > 0) {
      galleryLayout =
        <div className='hero-grid__gallery'>
          <div className='row'>
            {GridImages.map((item, index) => {
              const {
                Link,
                ImageUrl,
                ImageAltText,
                Title,
                TitleFontName = null,
                ImageTitleFontSize = {value: 'default'},
              } = item.fields;

              let imageLayout = "";
              let posVal = 0;
              let isEven = false;
              const newIndex = index + 1;

              if (newIndex % 2 === 0) {
                posVal = 6;
                isEven = true;
              }

              if (isMobile && index > 0) {
                posVal = 6;
              }

              let colVal = "7";
              if (newIndex % 3 === 0) {
                colVal = "5";
              }
              else if (newIndex % 4 === 0) {
                colVal = "7";
              } else if (isEven) {
                colVal = "5";
              }

              if (Link && Link.value && Link.value.href && ImageUrl && ImageUrl.value) {
                imageLayout =
                  <div className={`col col-12 col-md-${colVal}`}
                    key={"image-layout" + index}>
                    <RiseAndFade position={posVal}>
                      <a {...Link.value} href={Link.value.href + Link.value.querystring}
                        className='hero-grid__gallery-link animation-range'
                      >
                        <div className='hero-grid__gallery-link--image image-zoom-wrapper'>
                          <img
                            alt={
                              ImageAltText && ImageAltText.value
                                ? ImageAltText.value
                                : "hero-grid-image-alt"
                            }
                            src={defaultImg}
                            data-src={ImageUrl.value}
                            className="lazyload image-zoom"
                          />
                        </div>
                        {Title &&
                          Title.value &&
                          <span className={`hero-grid__gallery-link--label title-underline ${ImageTitleFontSize.value === 'default' ? "" : ImageTitleFontSize.value}`}
                            style={{ ...linkLabelStyle, fontFamily: TitleFontName && TitleFontName.value }}>
                            <Text field={Title} />
                          </span>
                        }
                      </a>
                    </RiseAndFade>
                  </div>
              }

              return imageLayout;
            })}
          </div>
        </div>
    }

    return galleryLayout;
  }

  if (GridImages && GridImages.length > 0) {
    return (
      <div className='hero-grid' style={heroGridStyle}>
        <div className='container'>
          {(Heading && Heading.value || Subheading && Subheading.value) &&
            <div className='hero-grid__head'>
              {Heading && Heading.value &&
                <div className={`hero-grid__title ${HeadingFontSize.value === 'default' ? "" : HeadingFontSize.value}`} style={{ fontFamily: HeadingFontName && HeadingFontName.value }}>
                  <Text field={Heading} />
                </div>
              }
              {Subheading && Subheading.value &&
                <div className={`hero-grid__description ${SubheadingFontSize.value === 'default' ? "" : SubheadingFontSize.value} ${Heading && Heading.value ? " hasMargin" : ""}`} style={{ fontFamily: SubheadingFontName && SubheadingFontName.value }}>
                  <Text field={Subheading} />
                </div>
              }
            </div>
          }
          {renderGallery()}
        </div>
      </div>
    )
  } else return null;
}

export default FeatureDevMicrositeHeroGrid;
