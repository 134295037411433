import React from "react";
import { withSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Common/BaseComponent";

class FeatureDevMicrositeRichText extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (window) {
      const el = window.document.getElementById("_goback");
      if (el) {
        el.onclick = function () {
          window.history.go(-1);
          return false;
        };
      }
    }
  }

  render() {
    const props = this.props;
    const componentStyle = {
      backgroundColor: props.fields.BackgroundColour && props.fields.BackgroundColour.value ? props.fields.BackgroundColour.value : "#ffffff",
      fontFamily: props.fields.FontName && props.fields.FontName.value
    }

    return (
      <div>
        {((props.fields && props.fields.Content) ||
          (!props.fields && props.sitecoreContext.pageEditing)) &&
          <BaseComponent margin="standard">
            {props.fields && props.fields.Content && (
              <div className="richtext-wrapper" style={componentStyle}>
                <div className={`container ${props.fields.Content && props.fields.Content.value.includes('class="a-primary-btn"' || 'class="a-secondary-btn"') ? "" : "hasButton"}`}>
                  <RichText field={props.fields.Content} />
                </div>
              </div>
            )}

            {!props.fields && props.sitecoreContext.pageEditing && (
              <div className="richtext-wrapper">
                <p>Rich Text component is not displayed due to following reasons: <br />
                  - Datasource is not set.
                </p>
              </div>
            )}
          </BaseComponent>
        }
      </div>
    );
  }
};

export default withSitecoreContext()(FeatureDevMicrositeRichText);
