import React from 'react';
import Tooltip from '../../../Common/Tooltip';
import defaultImg from '../../../../assets/image/gray.png';

const FeatureDevMicrositeInteractiveAerialView = (props) => {
  const {
    ImageUrl,
    Hotspots,
    IsFullwidth
  } = props.fields;

  const {
    RemoveDefaultMargin = ""
  } = props.params || {};

  const componentStyle = {
    margin: RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "0px" : "",
  }

  const renderTooltips = () => {
    if (Hotspots && Hotspots.length > 0) {

      let tooltipsLayout = Hotspots.map((item, index) => {
        const {
          TooltipLabel = null,
          TooltipLabelFontName = null,
          TooltipLabelFontSize = {value: 'default'},
          TooltipPosition = null,
          TooltipBgColour = null,
          TooltipFontColour = null,
          LeftPosition = null,
          TopPosition = null,
          Colour = null,
          HasBorder = null,
          BorderColour = null
        } = item.fields || {};

        let top = IsFullwidth.value ? parseFloat(TopPosition.value) + 1 : parseFloat(TopPosition.value);
        let left = IsFullwidth.value ? parseFloat(LeftPosition.value) + 0.5 : parseFloat(LeftPosition.value);

        const tooltipStyle = {
          top: TopPosition && TopPosition.value ? `${top}%` : "0",
          left: LeftPosition && LeftPosition.value ? `${left}%` : "0",
          position: "absolute"
        }

        const labelStyle = {
          backgroundColor: TooltipBgColour && TooltipBgColour.value ? TooltipBgColour.value : "#ffffff",
          color: TooltipFontColour && TooltipFontColour.value ? TooltipFontColour.value : "#000",
          fontFamily: TooltipLabelFontName && TooltipLabelFontName.value
        }

        const hotspotStyle = {
          backgroundColor: Colour && Colour.value ? Colour.value : "",
          borderColor: HasBorder.value && BorderColour.value ? BorderColour.value : ""
        }

        let tooltipLayout = (
          <div className='tooltip-container_tooltip' key={"tooltip-" + index} style={tooltipStyle}>
            <Tooltip content={TooltipLabel.value} fontSize={TooltipLabelFontSize.value} direction={TooltipPosition.value} style={labelStyle} delay="200">
              <div className={`tooltip-container_tooltip_hotspot ${HasBorder && HasBorder.value ? "hasBorder" : ""}`} style={hotspotStyle}></div>
            </Tooltip>
          </div>
        )

        return tooltipLayout;
      })

      return (
        <div className='tooltip-container'>
          {tooltipsLayout}
        </div>)

    } else return null;
  }

  return (
    ImageUrl && Hotspots &&
    <div className='interactive-aerial-view' style={componentStyle}>
      <div className={`${IsFullwidth && IsFullwidth.value ? "" : "container"}`}>
        <div className={`tooltip-image-container`}>
          {ImageUrl && ImageUrl.value &&
            <img
              alt="interactive-aerial-view-image"
              data-src={ImageUrl.value}
              className="lazyload"
              src={defaultImg}
              onError={(e) => {
                e.target.src = defaultImg
                e.target.style.aspectRatio = '16/9'
              }}

            />
          }
          {renderTooltips()}
        </div>
      </div>
    </div>
  );

};

export default FeatureDevMicrositeInteractiveAerialView;
