import React, { useEffect, useState } from 'react';
import Flickity from "react-flickity-component"
import chevronRight from '../../../../assets/image/chevron-right.png';

const FeatureDevMicrositeImageCarousel = (props) => {

  const {
    ImageGallery: images = [],
    Colour = null,
    CaptionFontName = null,
    CaptionFontSize = {value: 'default'}
  } = props.fields;

  const [flkty, setFlkty] = useState(null)

  const flktyOptions = {
    pageDots: false
  }

  useEffect(() => {
    // after initialize
    if (flkty) {

      flkty.on('change', (index) => {
        let selectedCarousel = document.getElementsByClassName(`crsl-${flkty.guid}`)
        let selectedHTMLBtns = selectedCarousel[0].nextSibling.querySelectorAll('.custom-navigation-bar-buttons-button')
        let selectedPage = selectedCarousel[0].nextSibling.querySelector('#selected-page')
        // update current page
        selectedPage.textContent = `${index + 1}`

        // apply selected style to index
        Array.from(selectedHTMLBtns).forEach((button, i) => {
          if (button.classList.contains('is-active')) {
            button.classList.remove('is-active')
            button.style.backgroundColor = ""
          }
          if (i === index) {
            button.classList.add('is-active')
            if (Colour && Colour.value) button.style.backgroundColor = Colour.value
          }
        })
      })
    }
  }, [flkty])

  const renderCarouselNavigation = () => {

    return (
      <div className='custom-navigation-bar-wrapper'>
        <div className='custom-navigation-bar'>
          <div className='custom-navigation-bar-buttons'>
            {flkty.slides.map((slide, index) => {
              return (
                <button key={index}
                  className={`custom-navigation-bar-buttons-button ${flkty.selectedIndex === index && 'is-active'}`}
                  style={{ backgroundColor: flkty.selectedIndex === index && Colour && Colour.value ? Colour.value : "" }}
                  index={index}
                  onClick={() => flkty.select(index)}>
                </button>
              )

            })}
          </div>
          <div className='custom-navigation-bar-actions'>
            <button onClick={() => { flkty.previous() }}>
              <img src={chevronRight} alt="prev" className="chevron-left"></img>
            </button>
            {/* pagination */}
            <div className="custom-navigation-bar-actions-pagination">
              <div id="selected-page" className="page-number">{flkty.selectedIndex + 1}</div> <span>/</span>  <span className="page-number">{flkty.slides.length}</span>

            </div>

            <button onClick={() => flkty.next()}>
              <img src={chevronRight} alt="next" className="chevron-right"></img>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`image-carousel-wrapper ${CaptionFontSize.value === 'default' ? "" : CaptionFontSize.value}`}>

      {images && images.length > 0 && (
        <Flickity
          className={`carousel crsl-${flkty && flkty.guid}`}
          options={flktyOptions}
          flickityRef={ref => setFlkty(ref)}
          reloadOnUpdate={true}
          static={true}
        >
          {images.map((item, index) => {
            const { ImageUrl: url, ImageAltText: alt, Caption: caption, ImageOrientation } = item.fields;
            if (url && url.value) {
              return (
                <div className={`carousel-slide ${ImageOrientation && ImageOrientation.value === 'portrait' ? 'portrait' : ''}`} key={`carousel-${index}`}>
                  <img
                    src={url.value}
                    className="carousel-slide-cell"
                    key={`carousel${index}`}
                    alt={alt && alt.value ? alt.value : "carousel_image"}
                  />
                  {caption.value && <p style={{ fontFamily: CaptionFontName && CaptionFontName.value }}>{caption.value}</p>}
                </div>

              );
            } else return null;
          })}
        </Flickity>
      )}
      {/* custom nav */}
      {flkty && images.length && renderCarouselNavigation()}
    </div>

  )
}

export default FeatureDevMicrositeImageCarousel;
