
import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
/**
 * @method PartnersComponent
 * @param { BackgroundColour, FontColour, Heading, Subheading, Partners } props.fields
 * @returns The main Partners Component
 */
const FeatureDevMicrositePartners = (props) => {
  let { BackgroundColour,
    FontColour,
    Heading,
    HeadingFontName,
    HeadingFontSize = {value: 'default'},
    Subheading,
    SubheadingFontName,
    SubheadingFontSize = {value: 'default'},
    Partners } = props.fields;

  // Checking of default colors. If there's none set, default colors were assigned
  // Defaults: BackgroundColor = 'white', Color = 'black'
  const componentStyle = {
    color: FontColour && FontColour.value ? FontColour.value : "#000000",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#ffffff",
  }

  return (
    Partners && Heading && Subheading &&
    <div className="partners-bg" style={componentStyle}>
      <div className="partners">
        <PartnersHeading items={{ Heading, Subheading }} HeadingFontName={HeadingFontName} SubheadingFontName={SubheadingFontName} HeadingFontSize={HeadingFontSize} SubheadingFontSize={SubheadingFontSize} />
        {
          Partners.length > 0 &&
          <div className={`logo-grid ${Heading.value !== '' || Subheading.value !== '' ? 'headers-present' : ''}`}>
            <PartnersGridItems Partners={Partners} />
          </div>
        }
      </div>
    </div>)
};

/**
 * @method PartnersHeading
 * @param { items } props 
 * @returns Partners Heading and Subheading
 */
const PartnersHeading = (props) => {
  const { HeadingFontName = null, SubheadingFontName = null } = props
  return (
    <div>
      {
        // Iteration of items array
        Object.keys(props.items).map((item, index) => {
          // Condition whether to display a heading/subheading if set or not.
          let itemType = item.toString();
          if(itemType === 'Heading') {
            return (props.items[item].value || props.items[item].value !== "") && <div className={`${itemType} ${props.HeadingFontSize.value === 'default' ? "" : props.HeadingFontSize.value}`} key={"partners-heading-" + index} style={{fontFamily: HeadingFontName && HeadingFontName.value }}><RichText field={props.items[item]} /></div>
          }
          if(itemType === 'Subheading') {
            return (props.items[item].value || props.items[item].value !== "") && <div className={`${itemType} ${props.SubheadingFontSize.value === 'default' ? "" : props.SubheadingFontSize.value}`} key={"partners-heading-" + index} style={{fontFamily: SubheadingFontName && SubheadingFontName.value }}><RichText field={props.items[item]} /></div>
          }
        })
      }
    </div >
  )
}

/**
 * @method PartnersGridItems
 * @param { Partners } props 
 * @returns Set of liked logos
 */
const PartnersGridItems = (props) => {
  return (
    // Iteration of Partners array and props spreading
    props.Partners.map((item, index) => {
      const imageElement = <img className="card-img" src={item.fields.LogoImageUrl.value} alt={item.fields.LogoImageAlt.value} />
      return item.fields.LogoLink.value.href !== "" ? <a className="grid-card" {...item.fields.LogoLink.value} key={"partners-grid-item-" + index}>{imageElement}</a> : <div className="grid-card" key={"partners-grid-item-" + index}>{imageElement}</div>
    })
  )
}

export default FeatureDevMicrositePartners;
