import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import RetailRiseFade from '../../../Common/Animations/RetailRiseFade';

const FeatureDevMicrositePressListing = (props) => {
  const { PressListingItems } = props.fields

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window) {
      if (window.outerWidth <= 1180) {
        setIsMobile(true)
      }
    }
  }, [])

  return (
    PressListingItems && PressListingItems.length > 0 &&
    <div className="press-list">
      {
        PressListingItems.map((item, index) => {
          return (
            !((!isMobile && index > 5) || (isMobile && index > 1)) ? <PressItemCard key={index} {...item} /> :
              <RetailRiseFade key={index} position={index}><PressItemCard key={index} {...item} /></RetailRiseFade>
          )
        })
      }
    </div>
  )};

const PressItemCard = (props) => {
  const { Title, ImageUrl, ImageAlt, PublicationName, PublicationDate, CtaLink, CtaLabel } = props.fields
  const $ = (fieldName) => {
    return fieldName.value
  }
  return (
    <a {...$(CtaLink)} className='press-card'>
      <div className='press-card-img'>
        <img src={$(ImageUrl)} alt={$(ImageAlt)} />
      </div>
      { (($(PublicationDate) || $(PublicationDate) !== '') || $(PublicationName) || $(PublicationName) !== '') && 
        <div className='press-card-pub'>
            <span className='press-card-pub-title'>{$(PublicationName) || ''}</span>
            { ($(PublicationDate) !== '') && <span>{$(PublicationDate)}</span>}
        </div>
      }
      {
        ($(Title) || $(Title) !== '') &&
        <div className='press-card-title'>
          <span>
            {$(Title)}
          </span>
        </div>
      }
      <span className='press-card-link'>
        <span>{$(CtaLabel)}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 5H20V10M20 14V20H5V5H11" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M19.5 4.5L12 12" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </span>
    </a>
  )
}

export default FeatureDevMicrositePressListing;
