import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const FeatureDevMicrositeSolidColour = (props) => {
  const {
    Title,
    TitleColour,
    TitleFontName,
    TitleFontSize = {value: 'default'},
    BackgroundColour
  } = props.fields;

  const solidColourStyle = {
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#3d4a39"
  }

  const titleColourStyle = {
    color: TitleColour && TitleColour.value ? TitleColour.value : "#ffffff",
    fontFamily: TitleFontName && TitleFontName.value
  }

  return (
    <div className='solid-colour' style={solidColourStyle}>
      <div className='container'>
        <div className={`solid-colour__title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={titleColourStyle}>
          <Text field={Title} />
        </div>
      </div>
    </div>
  )
};

export default FeatureDevMicrositeSolidColour;
