import React from 'react';

const BaseComponent = (props) => {
    const {
        margin,
        sectionId,
        setVisible
    } = props

    const setMargin = () => {
        let marginClass = "";
        if (margin === "standard") {
            marginClass = "standard";
        }
        return marginClass;
    };

    const showBase = () => {
        let visible = true;
        if (setVisible && setVisible === "hide") {
            visible = false;
        }
        return visible;
    };

    if (showBase()) {
        return (
            <div
                className={`base-component ${setMargin()}`}
                id={sectionId ? sectionId : ""}
            >
                {props.children}
            </div>
        );
    } else return null
};

export default BaseComponent;
