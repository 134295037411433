import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const FeatureDevMicrositeFactsAndFigures = (props) => {
  const {
    Title,
    TitleFontName,
    TitleFontSize = {value: 'default'},
    Description,
    DescriptionFontName,
    DescriptionFontSize = {value: 'default'},
    SelectedItems,
    BackgroundColour,
    FontColour,
    Footnote,
    FootnoteFontName,
    FootnoteFontSize = {value: 'default'},
    } = props.fields;

  const componentStyle = {
    color: FontColour && FontColour.value ? FontColour.value : "#ffffff",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#3d4a39"
  }

  const renderList = () => {
    if (SelectedItems && SelectedItems.length > 0) {
      let hasIcons = false;
      let figuresLayout = SelectedItems.map((item, index) => {
        const {
          Title = null,
          Description = null,
          IconName = null,
          TitleFontName = null,
          DescriptionFontName = null,
          TitleFontSize = {value: 'default'},
          DescriptionFontSize = { value: 'default'}
        } = item.fields || {};

        const selectedItemStyle = {
          titleFontName: TitleFontName && TitleFontName.value,
          descriptionFontName: DescriptionFontName && DescriptionFontName.value
        }

        let figuresListLayout = "";

        if ((Title && Title.value) || (Description && Description.value)) {
          {Title && Title.value && Description && Description.value === "" ?
            figuresListLayout = (
              <div key={"facts-figures" + index} className='facts-figures__item col col-12 col-md-6 col-xl-4'>
                <div className='facts-figures__item-icon'>
                  <i className={`icon icon-${IconName && IconName.value ? IconName.value : ""}`} />
                </div>
                <div className='facts-figures__item-details title-content'>
                  <div className={`facts-figures__item-title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={{ fontFamily: selectedItemStyle.titleFontName }}>
                    <RichText field={Title} />
                  </div>
                </div>
              </div>
            )
          :
            figuresListLayout = (
              <div key={"facts-figures" + index} className='facts-figures__item col col-12 col-md-6 col-xl-4'>
                <div className='facts-figures__item-icon'>
                  <i className={`icon icon-${IconName && IconName.value ? IconName.value : ""}`} />
                </div>
                <div className={`facts-figures__item-details ${(Title && Title.value === "" && Description && Description.value) ? "description-content" : ""}`}>
                  {Title && Title.value &&
                    <div className={`facts-figures__item-title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={{ fontFamily: selectedItemStyle.titleFontName }}>
                      <RichText field={Title} />
                    </div>
                  }
                  {Description && Description.value &&
                    <div className={`facts-figures__item-description ${DescriptionFontSize.value === 'default' ? "" : DescriptionFontSize.value}`} style={{ fontFamily: selectedItemStyle.descriptionFontName }}>
                      <RichText field={Description} />
                    </div>
                  }
                </div>
              </div>
            )
          }
        }
        
        if (IconName && IconName.value) {
          hasIcons = true
        }

        return figuresListLayout;
      })

      return (
        <div className={`facts-figures__content row ${hasIcons ? "" : "no-icons"}`}>
          {figuresLayout}
        </div>)
    } else return null;
  }

  const renderFootnotes = () => {
    const footnoteStyle = {
      fontFamily: FootnoteFontName && FootnoteFontName.value ? FootnoteFontName.value : "markpro"
    }
    return (
      <div className={'facts-figures__footnote row'}>
        <div className={`facts-figures__footnote-item-description ${FootnoteFontSize.value === 'default' ? "" : FootnoteFontSize.value}`} style={footnoteStyle}>
          <RichText field={Footnote} />
        </div>
      </div>)
  }

  return (
    SelectedItems && SelectedItems.length > 0 &&
    <div className='facts-figures' style={componentStyle}>
      <div className='container'>
        {Title && Title.value &&
          <div className={`facts-figures__title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value} ${Description && Description.value === "" ? "no-desc" : ""}`} style={{ fontFamily: TitleFontName && TitleFontName.value }}>
            <Text field={Title} />
          </div>
        }
        {Description && Description.value &&
          <div className='facts-figures__container'>
            <div className={`facts-figures__description ${DescriptionFontSize.value === 'default' ? "" : DescriptionFontSize.value}`} style={{ fontFamily: DescriptionFontName && DescriptionFontName.value }}>
              <Text field={Description} />
            </div>
          </div>
        }
        {renderList()}
        {Footnote && Footnote.value && renderFootnotes()}
      </div>
    </div >
  )
}

export default FeatureDevMicrositeFactsAndFigures;
