import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

class FoundationLayoutMain extends React.Component {
  render() {
    return (
      <div className="main-layout">
        <Placeholder name="jss-header" rendering={this.props.rendering} />
        <Placeholder name="jss-body" rendering={this.props.rendering} />
      </div>
    );
  }
}

export default FoundationLayoutMain;
