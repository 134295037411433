import React, { Component } from "react";

class RetailRiseFade extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (window) {
            window.addEventListener("scroll", this.handleScroll);
        }
    }

    handleScroll = () => {
        const imageWrapperList = document.querySelectorAll(".animation-rise-and-fade");
        if (imageWrapperList) {
            for (let i = 0; i < imageWrapperList.length; i++) {
                let imageWrapper = imageWrapperList[i];
                let position = imageWrapper.getBoundingClientRect();
                let isShowing = window.innerHeight >= position.top;
                let transformValue = imageWrapper.style.transform.split(/\w+\(|\);?/);
                let value = transformValue[1].split('%')[0];
                let opacity = 0;
                if (value > 0) {
                    if (isShowing) {
                        value = value - 1;
                        opacity = opacity + 0.3;
                    }
                }
                else {
                    value = 0;
                    opacity = 1;
                }
                imageWrapper.style.transform = `translateY(${value}%)`;
                imageWrapper.style.opacity = opacity;
            }
        }
    }


    render() {
        const { position } = this.props || {};
        let divStyle, positionValue;
        if (!isNaN(position)) {
            positionValue = position;
        }
        else {
            positionValue = 0;
        }
        divStyle = {
            transform: `translateY(${positionValue}%)`
        }
        return (
            <div className="animation-rise-and-fade" style={divStyle}>
                {this.props.children}
            </div>
        )
    }
}

export default RetailRiseFade;