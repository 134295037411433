import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const defaultColor = "#3d4a39";
const whiteColor = "#ffffff";

const FeatureDevMicrositeMainNavigation = (props) => {
  const {
    SiteLogoUrl,
    SiteLogoAltText,
    SiteUrl,
    EnquireLink,
    EnquireIconUrl,
    NavigationLinks,
    NavigationFontName,
    NavigationFontSize = {value: 'default'},
    NavigationBackgroundColour,
    NavigationFontColour,
    FontColour
  } = props.fields;

  const [showMenu, setShowMenu] = useState(false);
  const [isTablet, setIsTablet] = useState(false);


  useEffect(() => {
    function handleResize() {
      setIsTablet(window.matchMedia('(min-width: 768px)').matches);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const textColor = {
    color: FontColour && FontColour.value ? FontColour.value : whiteColor,
    borderColor: FontColour && FontColour.value ? FontColour.value : whiteColor,
  }

  const navColor = {
    color: NavigationFontColour && NavigationFontColour.value ? NavigationFontColour.value : whiteColor
  }
  const NavWrapperBgColor = NavigationBackgroundColour && NavigationBackgroundColour.value ? NavigationBackgroundColour.value : defaultColor;
  const navWrapperStyle = {
    background: isTablet ? `linear-gradient(to right,${NavWrapperBgColor} 50%,rgba(0, 0, 0, 0.5) 50%)` : NavWrapperBgColor,
  };

  const setBodyScroll = (isNavShown) => {
    document.body.style.overflow = isNavShown ? 'hidden' : 'overlay'
  }


  const setMenuStats = (status) => {
    setBodyScroll(status)
    setShowMenu(status)
  }
  const renderLogo = () => {
    let renderLogoLayout = "";

    if (SiteLogoUrl && SiteLogoUrl.value) {
      if (SiteUrl && SiteUrl.value) {
        renderLogoLayout =
          <a href={SiteUrl.value} className='main-navigation__logo'>
            <img src={SiteLogoUrl.value} alt={SiteLogoAltText && SiteLogoAltText.value ? SiteLogoAltText.value : "site-logo-alt"} />
          </a>
      } else {
        renderLogoLayout =
          <div className='main-navigation__logo'>
            <img src={SiteLogoUrl.value} alt={SiteLogoAltText && SiteLogoAltText.value ? SiteLogoAltText.value : "site-logo-alt"} />
          </div>
      }
    }

    return renderLogoLayout;
  }

  return (
    <div className='main-navigation'>
      <div className='container'>
        <div className='row'>
          <div className='col col-1 col-md-3 col-lg-2'>
            <div className='main-navigation__nav' style={textColor}>
              <button className={`main-navigation__nav-menu`}
                onClick={() => setMenuStats(true)}>
                <i className='icon icon-hamburger' />
                <span style={{ fontFamily: NavigationFontName && NavigationFontName.value }}>{'Menu'}</span>
              </button>
              {showMenu &&
                <div className='main-navigation__nav-wrapper'
                  style={navWrapperStyle}>
                  <div className='container' style={navColor}>
                    <button className='main-navigation__nav-close'
                      onClick={() => setMenuStats(false)}>
                      <i className='icon icon-close' />
                      <span style={{ fontFamily: NavigationFontName && NavigationFontName.value }} >Close</span>
                    </button>
                    <div className={`main-navigation__nav-links ${NavigationFontSize.value === 'default' ? "" : NavigationFontSize.value}`}>
                      {NavigationLinks.map((item, index) => {
                        const {
                          Link,
                          LinkName,
                          // IsActive
                        } = item.fields

                        let navLinkLayout = "";
                        if (Link && Link.value && Link.value.href && LinkName && LinkName.value) {
                          navLinkLayout = (
                            <a key={LinkName.value + index}
                              {...Link.value}
                              href={Link.value.href + Link.value.querystring}
                            >
                              <span style={{ fontFamily: NavigationFontName && NavigationFontName.value }}><Text field={LinkName} /></span>
                            </a>
                          )
                        }

                        return navLinkLayout;
                      })}
                    </div>
                  </div>
                  <div className='main-navigation__nav-overlay'
                    onClick={() => setMenuStats(false)}
                  >
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='col col-10 col-md-6 col-lg-8'>
            {renderLogo()}
          </div>
          <div className='col col-1 col-md-3 col-lg-2'>
            <div className='main-navigation__enquire' style={textColor}>
              {EnquireLink &&
                EnquireLink.value &&
                EnquireLink.value.href &&
                <a {...EnquireLink.value}
                  href={EnquireLink.value.href + EnquireLink.value.querystring}
                >
                  {(EnquireIconUrl && EnquireIconUrl.value) ?
                    <img src={EnquireIconUrl.value} alt="enquire-icon-url" />
                    : <i className='icon icon-enquire' />
                  }
                  <span style={{ fontFamily: NavigationFontName && NavigationFontName.value }}>{EnquireLink && EnquireLink.value && EnquireLink.value.text ? EnquireLink.value.text : "Enquire"}</span>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

export default FeatureDevMicrositeMainNavigation;
