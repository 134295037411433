import React, { useEffect, useState } from "react";

const Accordion = (props) => {
    const {
        id,
        items = [],
        selectedId,
        setSelectedId,
        selectedItemImage,
        collapseItemImage,
        styles,
    } = props

    const accordionClickHandler = (e, index) => {
        e.preventDefault()
        let accordion = document.getElementById(id)
        if (accordion) {
            let accordionItems = accordion.querySelectorAll('.accordion-item')
            accordionItems.forEach(item => item.classList.remove('active'))
            e.target.parentElement.classList.toggle('active')
            setSelectedId(index)
        }
    }

    // const [currentPage, setCurrentPage] = useState(1)
    // const [floorsPerPage] = useState(4)
    // const indexOfLastFloor = currentPage * floorsPerPage
    // const indexOfFirstFloor = indexOfLastFloor - floorsPerPage
    // const currentFloors = items.length > 4 ? items.slice(indexOfFirstFloor, indexOfLastFloor) : items
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollMaxHeight, setScrollMaxHeight] = useState();
    const [arrowBtnEnabled, setArrowBtnEnabled] = useState(false)
    const [upBtnEnabled, setUpBtnEnabled] = useState(false)
    const [downBtnEnabled, setDownBtnEnabled] = useState(false)
    const [currentSection, setCurrentSection] = useState(1)
    const [totalSection, setTotalSection] = useState(0)
    const [sectionFixedHeight, setSectionFixedHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(0);

    const handleScroll = event => {
        setScrollTop(event.currentTarget.scrollTop);
    };

    useEffect(() => {
        const accordionContainer = document.querySelector('.accordion')
        const ulContainer = document.querySelector('.accordion-controls') //content height
        setScrollMaxHeight(ulContainer.scrollHeight)
        setSectionFixedHeight(accordionContainer.offsetHeight)

        let totalSection = Math.ceil(ulContainer.scrollHeight / accordionContainer.offsetHeight)

        switch (totalSection) {
            case totalSection > 1: {
                setArrowBtnEnabled(true)

            }
        }
        if (totalSection > 1) {
            setArrowBtnEnabled(true)
            setDownBtnEnabled(true)
            setTotalSection(totalSection)
        }

        // console.log('scrollTop', scrollTop)
        // console.log('total section', totalSection)
        // setTotalSection()
        // divide max height / accordion height
        // qoutient will be the basis of total page
    }, []) //initial
    useEffect(() => {
        //enable/disable buttons
        // console.log('initalmaxHeight', scrollMaxHeight)
        // console.log('currentSection', currentSection)
        if (currentSection > 1) setUpBtnEnabled(true)
        else setUpBtnEnabled(false)

        if (currentSection === totalSection) setDownBtnEnabled(false)
        else setDownBtnEnabled(true)

        //update scroll position
        // console.log('scrollTop', scrollTop)
        if (currentSection) {
            // console.log('scrollPos', scrollPosition)
            const accordion = document.querySelector('.accordion')

            accordion.scrollTop = scrollPosition;
        }
    }, [currentSection]); //current section

    useEffect(() => {
        // if (scrollMaxHeight > )
    }, [scrollMaxHeight])

    const nextPage = (e) => {
        e.preventDefault()
        setCurrentSection(prev => prev + 1)
        setScrollPosition(scrollPosition + sectionFixedHeight)

        const accList = document.querySelector('.accordion')
        const animationReset = setTimeout(() => accList.classList.remove('acc-animate'), 500)

        if(accList) {
            accList.classList.add('acc-animate')
        }

        return () => clearTimeout(animationReset)

    };

    const prevPage = (e) => {
        e.preventDefault()
        setCurrentSection(prev => prev - 1)
        setScrollPosition(scrollPosition - sectionFixedHeight)

        const accList = document.querySelector('.accordion')
        const animationReset = setTimeout(() => accList.classList.remove('acc-animate'), 500)

        if(accList) {
            accList.classList.add('acc-animate')
        }

        return () => clearTimeout(animationReset)

    };


    const linkLabelStyle = {
        backgroundImage: `linear-gradient(transparent calc(100% - 1px), ${styles.color} 1px)`,
    }

    const svgFillColor = styles && styles.color ? styles.color : '#fff'

    return (
        <div id="floor-plans-accordion">
            {arrowBtnEnabled &&
                <button className={`floor-plans-button ${(upBtnEnabled ? 'up' : 'up btn-disabled')}`}
                    onClick={prevPage}>
                    <div className='btn-arrow-up' style={{ transform: 'rotate(180deg)' }}>
                        <svg id="arrow-btn-disable" width="56" height="56" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1,40a39,39 0 1,0 78,0a39,39 0 1,0 -78,0" stroke={svgFillColor} stroke-width="2" />
                            <path d="M52 36 40 48 28 36" stroke={svgFillColor} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </button>
            }
            <div className={`accordion ${arrowBtnEnabled ? "arrows-active" : ""}`} id={id} onScroll={handleScroll}>
                <ul className="accordion-controls" aria-label="Accordion Control Group Buttons" >
                    {items.map((floorPlan, index) =>
                        <li className='accordion-li' key={"accordion-item-" + index}>
                            <div key={floorPlan.id} className={`accordion-item ${selectedId === index && 'active'}`} >
                                {/* header */}
                                <button
                                    aria-controls={`content-${index}`}
                                    aria-expanded={selectedId === index ? true : false}
                                    id={`accordion-control-${index}`}
                                    className="animation-range"
                                    onClick={(e) => { accordionClickHandler(e, index) }}>
                                    <div>

                                        {selectedId === index ?
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm.324-20.763L20.43 15H8v2h12.429l-4.105 3.763 1.352 1.474 6-5.5a1 1 0 0 0 0-1.474l-6-5.5-1.352 1.474z" fill={svgFillColor} />
                                            </svg>


                                            : <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="15" stroke={svgFillColor} stroke-width="2" />
                                                <path fill={svgFillColor} d="M8 15.5h16v2H8z" />
                                                <path fill={svgFillColor} d="M17 8.5v16h-2v-16z" />
                                            </svg>}


                                        {/* {selectedItemImage && collapseItemImage && <img src={selectedId === index ? selectedItemImage : collapseItemImage}></img>} */}
                                        <span style={{ ...linkLabelStyle, fontFamily: floorPlan.fields.FloorNameFontName && floorPlan.fields.FloorNameFontName.value }} className={`title-underline ${floorPlan.fields.FloorNameFontSize.value === 'default' ? "" : floorPlan.fields.FloorNameFontSize.value}`}>{floorPlan.fields.FloorName.value}</span>
                                    </div>
                                </button>
                                {/* content */}
                                <div
                                    aria-hidden={selectedId === index ? false : true}
                                    id={`content-${index}`}
                                    className={`accordion-item-content ${floorPlan.fields.PrecinctsNameFontSize && (floorPlan.fields.PrecinctsNameFontSize.value === 'default' ? "" : floorPlan.fields.PrecinctsNameFontSize.value)}`} >
                                    <ul>
                                        {floorPlan.fields.Precincts.map((precinct, ind) =>
                                            <li style={{ "--bulet-color": precinct.fields.Colour.value, fontFamily: floorPlan.fields.PrecinctsFontName && floorPlan.fields.PrecinctsFontName.value }} key={"accordion-item-" + ind}>{precinct.fields.PrecinctName.value}</li>
                                        )}
                                    </ul>
                                </div>
                                <hr style={{ "border-color": styles && styles.color ? styles.color : '#fff' }}></hr>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            {arrowBtnEnabled &&
                <button className={`floor-plans-button ${(downBtnEnabled ? 'down' : 'down btn-disabled')}`}
                    onClick={nextPage}>
                    <div className='btn-arrow-down'>
                        <svg id="arrow-btn-disable" width="56" height="56" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1,40a39,39 0 1,0 78,0a39,39 0 1,0 -78,0" stroke={svgFillColor} stroke-width="2" />
                            <path d="M52 36 40 48 28 36" stroke={svgFillColor} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </button>
            }
            {/* {<span>{scrollTop}</span>} */}
        </div>
    )
}

export default Accordion;