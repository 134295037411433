import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Flickity from "react-flickity-component";

const FeatureDevMicrositeFactsAndFiguresWithImage = (props) => {
  const {
    Heading,
    HeadingFontName,
    HeadingFontSize = {value: 'default'},
    Subheading,
    SubheadingFontName,
    SubheadingFontSize = {value: 'default'},
    HeaderBackgroundColour,
    HeaderFontColour,
    SelectedItems,
    BackgroundColour,
    FontColour,
    ImagePosition,
    SlideshowImages,
    SlideshowDelay,
    Footnote,
    FootnoteFontName,
    FootnoteFontSize = {value: 'default'},
  } = props.fields;

  const {
    RemoveDefaultMargin = ""
  } = props.params || {};

  const componentStyle = {
    color: FontColour && FontColour.value ? FontColour.value : "#ffffff",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#3d4a39"
  }

  const headerStyle = {
    color: HeaderFontColour && HeaderFontColour.value ? HeaderFontColour.value : "#ffffff",
    backgroundColor: HeaderBackgroundColour && HeaderBackgroundColour.value ? HeaderBackgroundColour.value : "#3d4a39",
    marginTop: RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "0px" : ""
  }

  const renderImage = () => {
    const flickityOptions = {
      autoPlay: SlideshowDelay && SlideshowDelay.value ? (SlideshowDelay.value * 1000) : 5000,
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      draggable: false,
      selectedAttraction: 0.01,
      setGallerySize: false,
      fullscreen: true
    }

    return (
      <div className='slideshow'>
        {SlideshowImages && SlideshowImages.length > 0 && (
          <Flickity className={"carousel"} options={flickityOptions} reloadOnUpdate static>
            {SlideshowImages.map((item, index) => {
              const { ImageUrl: url, ImageAltText: alt } = item.fields;
              if (url && url.value) {
                return (
                  <img
                    src={url.value}
                    className="carousel-cell"
                    key={`carousel${index}`}
                    alt={alt && alt.value ? alt.value : "carousel_image"}
                  />
                );
              } else return null;
            })}
          </Flickity>
        )}

      </div>)
  }


  const renderFootnote = (itemsHasIcons = false) => {

    const footnoteStyle = {
      fontFamily: FootnoteFontName && FootnoteFontName.value ? FootnoteFontName.value : "markpro"
    }

    return (<div className='facts-figures-image__footnote-item col-12'>
      <div className={`facts-figures-image__footnote-item__footnote-details ${FootnoteFontSize.value === 'default' ? "" : FootnoteFontSize.value}  ${itemsHasIcons ? "" : "no-icons"}`}>
        <div className='desc' style={footnoteStyle}>
          <RichText field={Footnote} />
        </div>
      </div>
    </div>)
  }

  const renderList = () => {
    if (SelectedItems && SelectedItems.length > 0) {
      let hasIcons = false;
      let figuresLayout = SelectedItems.map((item, index) => {
        const {
          Title = null,
          Description = null,
          IconName = null,
          TitleFontName = null,
          DescriptionFontName = null,
          DescriptionFontSize = {value: 'default'},
          TitleFontSize = {value: 'default'},
        } = item.fields || {};

        const selectedItemStyle = {
          titleFontName: TitleFontName && TitleFontName.value,
          descriptionFontName: DescriptionFontName && DescriptionFontName.value
        }

        let figuresListLayout = "";

        if ((Title && Title.value) || (Description && Description.value)) {
          figuresListLayout = (
            <div key={"facts-figures-image" + index} className='facts-figures-image__item col-12'>
              <div className='facts-figures-image__item-icon'>
                <i className={`icon icon-${IconName && IconName.value ? IconName.value : ""}`} />
              </div>

              <div className={`facts-figures-image__item-details ${(Title && Title.value === "") || (Description && Description.value === "") ? "single-content" : ""}`}>
                {Title && Title.value &&
                  <div className={`facts-figures__title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={{ fontFamily: selectedItemStyle.titleFontName }}>
                    <RichText field={Title} />
                  </div>
                }
                {Description && Description.value &&
                  <div className={`facts-figures__description ${DescriptionFontSize.value === 'default' ? "" : DescriptionFontSize.value}`} style={{ fontFamily: selectedItemStyle.descriptionFontName }}>
                    <RichText field={Description} />
                  </div>
                }
              </div>
            </div>
          )
        }
        if (IconName && IconName.value) {
          hasIcons = true
        }

        return figuresListLayout;
      })

      return (
        <div className={`facts-figures-image__text-content ${hasIcons ? "" : "no-icons"}`}>
          {figuresLayout}
          {Footnote && Footnote.value && renderFootnote(hasIcons)}
        </div>)
    } else return null;
  }


  return (
    SelectedItems && SelectedItems.length > 0 &&
    <div>
      {Heading && Heading.value || Subheading && Subheading.value ? (
            <div className='facts-figures-image-header' style={headerStyle}>
              <div className='container'>
                {Heading && Heading.value &&
                  <div className={`facts-figures-image-header__heading ${HeadingFontSize.value === 'default' ? "" : HeadingFontSize.value} ${Subheading && Subheading.value === "" ? "no-subheading" : ""}`} style={{ fontFamily: HeadingFontName && HeadingFontName.value }}>
                    <Text field={Heading} />
                  </div>
                }
                {Subheading && Subheading.value &&
                  <div className='facts-figures-image-header__container'>
                    <div className={`facts-figures-image-header__subheading ${SubheadingFontSize.value === 'default' ? "" : SubheadingFontSize.value}`} style={{ fontFamily: SubheadingFontName && SubheadingFontName.value }}>
                      <Text field={Subheading} />
                    </div>
                  </div>
                }
              </div>
            </div >
        ) : ""
      }
      <div className='facts-figures-image' style={componentStyle}>
        <div className={`row ${ImagePosition && ImagePosition.value && ImagePosition.value.toLowerCase() === "right" ? "is-reverse" : ""}`}>
          <div className='col-12 col-md-6 facts-figures-image__image'>
            {renderImage()}
          </div>
          <div className='col-12 col-md-6 facts-figures-image__text'>
            {renderList()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureDevMicrositeFactsAndFiguresWithImage;
