import React from 'react';

const FeatureDevMicrositeFullWidthImage = (props) => {
    const {
      ImageUrl,
      ImageAltText,
    } = props.fields;


    if (ImageAltText && ImageAltText.value && ImageUrl && ImageUrl.value) {
        return (
            <div className='full-width-image'>
                <img
                        alt={ImageAltText.value}
                        data-src={ImageUrl.value}
                        className="lazyload"
                    />
            </div>
        )
    }else return null;
}

export default FeatureDevMicrositeFullWidthImage;
