import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const FeatureDevMicrositeHeaderAndText = (props) => {
  const {
    Title,
    TitleFontName,
    TitleFontSize = {value: 'default'},
    Description,
    DescriptionFontName,
    DescriptionFontSize = {value: 'default'},
    BackgroundColour,
    FontColour
  } = props.fields;

  const componentStyle = {
    color: FontColour && FontColour.value ? FontColour.value : "#000000",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#ffffff"
  }


  return (
    Title && Description &&
    <div className='header-and-text' style={componentStyle}>
      <div className='container'>
        {Title && Title.value &&
          <div className={`header-and-text__title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={{ fontFamily: TitleFontName && TitleFontName.value }}>
            <Text field={Title} />
          </div>
        }
        {Description && Description.value &&
          <div className='header-and-text__container'>
            <div className={`header-and-text__description ${DescriptionFontSize.value === 'default' ? "" : DescriptionFontSize.value}`} style={{ fontFamily: DescriptionFontName && DescriptionFontName.value }}>
              <Text field={Description} /> 
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default FeatureDevMicrositeHeaderAndText;
