import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import defaultImg from "../../../../assets/image/gray.png";

const FeatureDevMicrositeCorpoLeasing = (props) => {
  const {
    LogoImageUrl = null,
    LogoImageAlt = null,
    LogoLink = null,
    ShortDescription = null,
    ShortDescriptionFontName = null,
    ShortDescriptionFontSize = {value: 'default'},
    Contacts = null,
    ContactNameFontName = null,
    ContactNameFontSize = {value: 'default'},
    ContactLinksFontName = null,
    ContactLinksFontSize = {value: 'default'},
    Button = null,
    BackgroundColour = null,
    FontColour = null,
  } = props.fields || {};

  const componentStyle = {
    color: FontColour && FontColour.value ? FontColour.value : "#000000",
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#e7e7e7",
  }

  if ((LogoImageUrl && LogoImageUrl.value) ||
    (ShortDescription && ShortDescription.value) ||
    (Contacts && Contacts.length > 0)) {

    return (
      <div className={`leasing-contacts `} style={componentStyle}>
        <div className="container">
          {LogoImageUrl && LogoImageUrl.value || ShortDescription && ShortDescription.value ? (

            <div className='leasing-contacts__header'>
              {LogoImageUrl && LogoImageUrl.value &&
                <div className="leasing-contacts__logo">
                  <a href={LogoLink.value.href} className={LogoLink && LogoLink.value.href ? null : 'inactive-link'} target={LogoLink.value.target}>
                    <img
                      alt={
                        LogoImageAlt && LogoImageAlt.value
                          ? LogoImageAlt.value
                          : "leasing-contacts-logo"
                      }
                      src={defaultImg}
                      data-src={LogoImageUrl.value}
                      className="lazyload"
                    />
                  </a>
                </div>
              }
              {ShortDescription && ShortDescription.value &&
                <div className={`leasing-contacts__shortdescription module-subtitle ${ShortDescriptionFontSize.value === 'default' ? "" : ShortDescriptionFontSize.value}`} style={{ fontFamily: ShortDescriptionFontName && ShortDescriptionFontName.value }}>
                  <Text field={ShortDescription} />
                </div>
              }
            </div>
          ) : null}
          {Contacts &&
            Contacts.length > 0 &&
            <div className="leasing-contacts__details">
              {Contacts.map((item, index) => {
                const {
                  ContactName = null,
                  ContactImageUrl = null,
                  ContactImageAltText = null,
                  ContactNumber = null,
                  ContactEmail = null,
                  CcEmail = null,
                  BccEmail = null,
                } = item.fields;

                let phoneNumberLayout = "";
                let mailtorecipients = "";
                if (ContactNumber && ContactNumber.value) {
                  let numberVal;
                  if (/;/g.test(ContactNumber.value)) {
                    numberVal = ContactNumber.value.split(";");
                    phoneNumberLayout =
                      numberVal.map((number, index) => {
                        return (
                          <div className="leasing-contacts__number"
                            key={`leasing-contact-number-${index}`}>
                            <a href={`tel:${number}`} style={{ color: componentStyle.color }}>
                              <Text field={{ value: number }} />
                            </a>
                          </div>
                        )
                      })
                  } else {
                    phoneNumberLayout =
                      <div className="leasing-contacts__number">
                        <a href={`tel:${ContactNumber.value}`} style={{ color: componentStyle.color }}>
                          <Text field={ContactNumber} />
                        </a>
                      </div>
                  }
                }

                if (ContactEmail && ContactEmail.value) {
                  mailtorecipients = `${ContactEmail.value}`
                  if (CcEmail && CcEmail.value) mailtorecipients += `?cc=${CcEmail.value}`
                  if (BccEmail && BccEmail.value) {
                    if (/\?/g.test(mailtorecipients)) mailtorecipients += `&bcc=${BccEmail.value}`
                    else mailtorecipients += `?bcc=${BccEmail.value}`
                  }
                }
                if ((ContactName && ContactName.value) ||
                  (ContactImageUrl && ContactImageUrl.value) ||
                  (ContactNumber && ContactNumber.value) ||
                  (ContactEmail && ContactEmail.value)) {
                  return (
                    <div className="leasing-contacts__details--wrapper module-subheading--large"
                      key={`leasing-contact-details-${index}`}>

                      {ContactImageUrl && ContactImageUrl.value &&
                        <div className="leasing-contacts__image">
                          <img
                            alt={
                              ContactImageAltText && ContactImageAltText.value
                                ? ContactImageAltText.value
                                : "leasing-contacts-logo"
                            }
                            src={defaultImg}
                            data-src={ContactImageUrl.value}
                            className="lazyload"
                          />
                        </div>
                      }
                      {ContactName && ContactName.value &&
                        <div className={`leasing-contacts__name ${ContactNameFontSize.value === 'default' ? "" : ContactNameFontSize.value}`} style={{ fontFamily: ContactNameFontName && ContactNameFontName.value }}>
                          <Text field={ContactName} />
                        </div>
                      }
                      <div className={`leasing-contacts__contacts ${ContactLinksFontSize.value === 'default' ? "" : ContactLinksFontSize.value}`} style={{ fontFamily: ContactLinksFontName && ContactLinksFontName.value }}>
                        {phoneNumberLayout}
                        {ContactEmail &&
                          ContactEmail.value &&
                          <div className="leasing-contacts__email">
                            <a href={`mailto:${mailtorecipients}`} style={{ color: componentStyle.color }}>
                              <Text field={{ value: "Email" }} />
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }
                else {
                  return null
                }
              })
              }
            </div>
          }

          {Button && Button.value && Button.value.href && Button.value.text && (
            <a
              href={Button.value.href}
              target={Button.value.target}
              className="leasing-contacts__button btn btn--medium btn--light-primary"
            >
              <Text field={{ value: Button.value.text }} />
            </a>
          )}
        </div>
      </div >
    )
  }
  else {
    return null
  }
};

export default FeatureDevMicrositeCorpoLeasing;
