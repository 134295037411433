import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import defaultImg from "../../../../assets/image/gray.png";

const FeatureDevMicrositeFooter = (props) => {
    const {
        LogoUrl,
        LogoAltText,
        LogoLink,
        CopyrightText,
        FooterLinks,
        FooterFontName,
        FooterFontSize = {value: 'default'},
    } = props.fields;

    const renderLogo = () => {
        let logoLayout = "";

        if (LogoUrl && LogoUrl.value) {
            if (LogoLink && LogoLink.value && LogoLink.value.href) {
                logoLayout =
                    <div className='footer__logo'>
                        <a {...LogoLink.value} href={LogoLink.value.href + LogoLink.value.querystring}>
                            <img
                                alt={
                                    LogoAltText && LogoAltText.value
                                        ? LogoAltText.value
                                        : "dev-footer-alt"
                                }
                                src={defaultImg}
                                data-src={LogoUrl.value}
                                className="lazyload"
                            />
                        </a>
                    </div>
            } else {
                logoLayout =
                    <div className='footer__logo'>
                        <img
                            alt={
                                LogoAltText && LogoAltText.value
                                    ? LogoAltText.value
                                    : "dev-footer-alt"
                            }
                            src={defaultImg}
                            data-src={LogoUrl.value}
                            className="lazyload"
                        />
                    </div>
            }
        }

        return logoLayout;
    }

    const renderFooterLinks = () => {
        let footerLinksLayout = "";

        if (FooterLinks && FooterLinks.length > 0) {
            let hasMoreLinksClass = FooterLinks.length > 5 ? "more-links" : "";
            footerLinksLayout =
                <div className={`footer__links ${FooterFontSize.value === 'default' ? "" : FooterFontSize.value} ${hasMoreLinksClass}`} style={{ fontFamily: FooterFontName && FooterFontName.value }}>
                    {FooterLinks.map((item, index) => {
                        const {
                            LinkTitle,
                            LinkUrl
                        } = item.fields;

                        let linkLayout = "";
                        if (LinkTitle && LinkTitle.value &&
                            LinkUrl && LinkUrl.value && LinkUrl.value.href) {
                            linkLayout =
                                <a key={LinkTitle.value + index}
                                    {...LinkUrl.value}
                                    href={LinkUrl.value.href + LinkUrl.value.querystring}
                                >
                                    <Text field={LinkTitle} />
                                </a>
                        }
                        return linkLayout;
                    })}
                </div>
        }

        return footerLinksLayout;
    }

    return (
        <div className='footer'>
            <div className='container'>
                {renderLogo()}
                <div className='row'>
                    <div className='col col-12 col-lg-6 col-xl-5'>
                        {CopyrightText &&
                            CopyrightText.value &&
                            <div className='footer__description' style={{ fontFamily: FooterFontName && FooterFontName.value }}>
                                <Text field={CopyrightText} />
                            </div>
                        }
                    </div>
                    <div className='col col-12 col-lg-6 col-xl-7'>
                        {renderFooterLinks()}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FeatureDevMicrositeFooter;
