export default [
  "castletowers",
  "21cityside",
  "midway",
  "narellan",
  "watergardens",
  "robina",
  "101albert",
  "brite"
];
