import React, { useState, Fragment } from "react";

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={showTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`tooltip-tip ${props.fontSize === 'default' ? "" : props.fontSize} ${props.direction || "top"}`} style={props.style}>
          {props.content.split(/\n/).map((line, i, arr) => i === arr.length - 1 ? line : <Fragment key={line}>{line}<br /></Fragment>)}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
