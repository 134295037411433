import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import defaultImg from "../../../../assets/image/gray.png";

const FeatureDevMicrositeEnquireBanner = (props) => {
  const {
    BackgroundImageUrl,
    BackgroundImageAltText,
    Heading,
    HeadingFontName,
    HeadingFontSize = {value: 'default'},
    Subheading,
    SubheadingFontName,
    SubheadingFontSize = {value: 'default'},
    CtaLink,
    CtaTheme,
    CtaFontName
  } = props.fields;

  return (
    Heading && Subheading && BackgroundImageUrl && CtaLink &&
    <div className='enquire-banner' >
      {
        BackgroundImageUrl && BackgroundImageUrl.value &&
        <div className='enquire-banner__image'>
          <img
            alt={
              BackgroundImageAltText && BackgroundImageAltText.value
                ? BackgroundImageAltText.value
                : "enquire-banner-alt"
            }
            src={defaultImg}
            data-src={BackgroundImageUrl.value}
            className="lazyload"
          />
        </div>
      }
      <div className='enquire-banner__container'>
        <div className='container'>
          <div>
            {Heading && Heading.value &&
              <div className={`enquire-banner__header ${HeadingFontSize.value === 'default' ? "" : HeadingFontSize.value}`} style={{ fontFamily: HeadingFontName && HeadingFontName.value }}>
                <Text field={Heading} />
              </div>
            }
            {Subheading && Subheading.value &&
              <div className={`enquire-banner__subheader ${SubheadingFontSize.value === 'default' ? "" : SubheadingFontSize.value} ${CtaLink && CtaLink.value && CtaLink.value.href && CtaLink.value.text ? "hasButton" : ""}`} style={{ fontFamily: SubheadingFontName && SubheadingFontName.value }}>
                <RichText field={Subheading} />
              </div>
            }
            {CtaLink && CtaLink.value && CtaLink.value.href && CtaLink.value.text &&
              <div className='enquire-banner__cta'>
                <a {...CtaLink.value}
                  className={`btn btn--${CtaTheme && CtaTheme.value ? CtaTheme.value : "primary"}`}
                  style={{ fontFamily: CtaFontName && CtaFontName.value }}
                >
                  {CtaLink.value.text}
                </a>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureDevMicrositeEnquireBanner;
