import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import defaultImg from "../../../../assets/image/gray.png";
import ScrollAnimation from "react-animate-on-scroll";

const FeatureDevMicrositeTextImage = (props) => {
  const {
    BackgroundColour,
    FontColour,
    Title,
    TitleFontName,
    TitleFontSize = {value: 'default'},
    Description,
    DescriptionFontName,
    DescriptionFontSize = {value: 'default'},
    CtaLink,
    CtaTheme,
    ImagePosition,
    ImageGallery
  } = props.fields;

  const textImageStyle = {
    backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#ffffff",
    color: FontColour && FontColour.value ? FontColour.value : "#000000"
  }

  const fontFamilyName = {
    titleFontName: TitleFontName && TitleFontName.value,
    descriptionFontName: DescriptionFontName && DescriptionFontName.value
  }

  const renderGallery = () => {
    let galleryLayout = "";

    if (ImageGallery && ImageGallery.length > 0) {
      let galleryClass = "";
      if (ImageGallery.length === 2) {
        galleryClass = "is-2";
      } else if (ImageGallery.length > 2) {
        galleryClass = "is-3";
      }

      galleryLayout =
        <div className={`text-image__gallery ${galleryClass}`}>
          <div className='row'>
            {ImageGallery.map((item, index) => {
              const {
                ImageUrl,
                ImageAlt,
                ImageAspectRatio
              } = item.fields;

              if (index > 2) {
                return;
              }

              let imageLayout = "";
              let colVal = "12";

              if (ImageGallery.length === 2) {
                colVal = "12";
                if (index === 1) {
                  colVal = "6"
                }
              } else if (ImageGallery.length > 2) {
                colVal = "10";
                if (index === 1) {
                  colVal = "4";
                } else if (index === 2) {
                  colVal = "8";
                }
              }

              let ratioClass = "";
              if (ImageAspectRatio && ImageAspectRatio.value) {
                if (ImageAspectRatio.value === "4:3") {
                  ratioClass = "is-ratio-4-3";
                } else if (ImageAspectRatio.value === "16:9") {
                  ratioClass = "is-ratio-16-9";
                } else if (ImageAspectRatio.value === "3:4") {
                  ratioClass = "is-ratio-3-4";
                }
              }

              if (ImageUrl && ImageUrl.value) {
                imageLayout =
                  <div className={`col col-12 col-lg-${colVal}`}
                    key={"text-image" + index}>
                    <div>
                      <div className={`text-image__img ${ratioClass}`}>
                        <img
                          alt={
                            ImageAlt && ImageAlt.value
                              ? ImageAlt.value
                              : "hero-grid-image-alt"
                          }
                          src={defaultImg}
                          data-src={ImageUrl.value}
                          className="lazyload"
                        />
                      </div>
                    </div>
                  </div>
              }
              return imageLayout;
            })}
          </div>
        </div>
    }
    return galleryLayout;
  }

  if (ImageGallery && ImageGallery.length > 0 && Description && Description.value) {
    return (
      <ScrollAnimation
        animateIn="animate__animated animate__fadeIn"
        animateOnce={true}
      >
        <div className='text-image' style={textImageStyle}>
          <div className='container'>
            <div className={`row 
        ${ImagePosition && ImagePosition.value
                && ImagePosition.value.toLowerCase() === "right" ? "is-reverse" : ""}
        `}>
              <div className='col col-12 col-md-6'>
                {renderGallery()}
              </div>

              {/* Divider */}
              <div className='col col-12 col-md-1'></div>

              <div className='col col-12 col-md-5'>
                <div className='text-image__details'>
                  {Title && Title.value &&
                    <div className={`text-image__title ${TitleFontSize.value === 'default' ? "" : TitleFontSize.value}`} style={{ fontFamily: fontFamilyName.titleFontName }}>
                      <Text field={Title} />
                    </div>
                  }
                  {Description && Description.value &&
                    <div className={`text-image__description ${DescriptionFontSize.value === 'default' ? "" : DescriptionFontSize.value}`} style={{ fontFamily: fontFamilyName.descriptionFontName }}>
                      <RichText field={Description} />
                    </div>
                  }
                  {CtaLink && CtaLink.value && CtaLink.value.href && CtaLink.value.text &&
                    < div className='text-image__cta' >
                      <a {...CtaLink.value}
                        className={`btn btn--${CtaTheme && CtaTheme.value ? CtaTheme.value : "primary"}`}
                        style={{ fontFamily: fontFamilyName.descriptionFontName }}
                      >
                        {CtaLink.value.text}
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation >
    )
  } else return null;
};

export default FeatureDevMicrositeTextImage;
