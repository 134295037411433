import React, { Component } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

class FeatureDevMicrositeVideoModule extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

    componentDidMount() {
      this.appendScript();

      // var iframe = document.getElementsByTagName("iframe");
      // var iframeList = Array.prototype.slice.call(iframe);
      // iframeList.forEach(this.iframeEvent);
      // // iframe.onload = function(){
      // //   console.log(iframe.contentWindow.document.body.scrollHeight);
      // //   iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
      // // }
    }

    iframeEvent(value, index, ar) {
      ar[index].onload = function(){
        ar[index].style.height = ar[index].contentWindow.document.body.scrollHeight + 'px';
      }
    }
  
    appendScript() {
      const { Object: object } = this.props.fields;
      if (object && object.value) {
        let parser = new DOMParser();
        let scripts = parser
          .parseFromString(object.value, "text/html")
          .body.querySelectorAll("script");
        if (scripts && scripts.length) {
          for (let i = 0; i < scripts.length; i++) {
            let scriptElement = document.createElement('script');
            scriptElement.textContent = scripts[i].textContent;
            document.head.appendChild(scriptElement);          }
        }
      }
    }


    
    render(){
      const {
        BackgroundColour,
        EmbedCode: object
      } = this.props.fields;
      
      const componentStyle = {
        backgroundColor: BackgroundColour && BackgroundColour.value ? BackgroundColour.value : "#f5f5f6"
      }
  
        if (object && object.value) {
          return (
            <div className="video-module" style={componentStyle}>
              <div className="video">
                <div className="container">
                  <div className={`content ${object && object.value.includes('</video>') ? "hasVideo" : object.value.includes('blingby.com') ? "hasBlingby" : "" }`}>
                    <RichText field={object} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else return null;
    }
}

export default FeatureDevMicrositeVideoModule;
